<template>
  <div>
    <div class="MyShop-header">
      <div class="MyShop-one">
        <p class="header-one">
          <van-icon name="arrow-left" size="24px" @click="goNativer" />
        </p>
        <p class="header-two">{{myshoppic.name}}的小店</p>
      </div>
      <div class="MyShop-center">
        <p class="center-one">
          <van-image round width="5rem" height="5rem" :src="myshoppic.imgUrl"/>
        </p>

        <div class="MyShop-footer">
          <!-- <p @click="payment(0)">
            <span>
           
            </span>
            <span>全部</span>
          </p> -->
          <p @click="payment(1)">
            <span>
             <img src="../../static/会员升级_slices/daifukuan.png" alt /> 
            </span>
            <span>待付款</span>
          </p>
          <p @click="payment(2)">
            <span>
            <img src="../../static/会员升级_slices/gouwuche.png" alt />  
            </span>
            <span>待发货</span>
          </p>
           <p @click="payment(3)">
            <span>
              <img src="../../static/会员升级_slices/daishouhuo.png" alt />
            </span>
            <span>待收货</span>
          </p>
          <p @click="payment(4)">
            <span>
              <img src="../../static/会员升级_slices/shouhou.png" alt />
            </span>
            <span>退货/售后</span>
          </p>
        </div>
      </div>
      <div class="MyShop-footer-one">
        <!-- <van-cell title="我的小店" icon="shop-o" @click="myshop">
          <template #right-icon>
            <van-icon name="arrow" class="search-icon" />
          </template>
        </van-cell> -->
        <van-cell title="商家管理中心" icon="newspaper-o" @click="management">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #right-icon>
            <van-icon name="arrow" class="search-icon" />
          </template>
        </van-cell>
        <!-- <van-cell title="推广订单" icon="../../assets/订单.png"> -->
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <!-- <template #right-icon>
            <van-icon name="arrow" class="search-icon" />
        </template>-->
        <!-- </van-cell> -->
        <van-cell title="收货地址" icon="location-o" @click="shipping">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #right-icon>
            <van-icon name="arrow" class="search-icon" />
          </template>
        </van-cell>
      </div>
      <div class="MyShop-footer-two">
        <van-cell title="购物车" icon="shopping-cart-o" @click="shopwuche">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #right-icon>
            <van-icon name="arrow" class="search-icon" />
          </template>
        </van-cell>
        <!-- <van-cell title="领劵中心" icon="shop-o" @click="Bring">
         
          <template #right-icon>
            <van-icon name="arrow" class="search-icon" />
          </template>
        </van-cell>-->
      </div>
    </div>
  </div>
</template>

<script>
import { getMyShoppic } from "@/api/MyShop/MyShop";
import { Toast } from 'vant';
// import { mapState } from 'vuex';
export default {
  name: "MyShop",

  data() {
    return {
      id: "",
      isFirst:false,//是否是第一页
      myshoppic:[]
    };
  },

  mounted() {},
  computed: {
    // ...mapState(["session"])
  },
  created() {
    if(this.$route.query.isFirst!=null)
    {
      this.isFirst = this.$route.query.isFirst;
    }
    //获取app传递过来的session
    if(this.$route.query.WoSession!=null)
    {
      this.$store.commit('setSession', this.$route.query.WoSession)
    }
   
    this.id = this.$route.query.id;
    this.getMyShoppictype()
  },
  methods: {
    getMyShoppictype(){
      getMyShoppic().then(res =>{
        if(res.data.code == 0){
          console.log(res)
          this.myshoppic = res.data.data
        }
        else{
          Toast(res.data.msg)
        }
      })
    },
    payment(n) {
      this.$router.push({ path: "fansOrderdatanew", query: { active: n } });
    },
    myshop() {
      this.$router.push({
        path: "WoShopone",
        query: {
          id: this.id,
        },
      });
    },
    management() {
      this.$router.push({ path: "Merchant" });
    },
    shopwuche() {
      this.$router.push({ path: "shoppingcart" });
    },
    Bring() {
      this.$router.push({ path: "demo" });
    },
    shipping() {
      this.$router.push({ path: "shippingAddress" });
    },
    goNativer() {
      if(this.isFirst){
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up");
        }
        if (isAndroid) {
          window.android.close();
        }
      }
      else{
        this.$router.back(-1);
        this.pageRefresh();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.MyShop-header {
  position: fixed;
  background:url('../../assets/tupian.png') no-repeat ;
  background-size:100%;
  left: 0;
  right: 0;
  height: 215px;
  .MyShop-one {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    font-size: 17px;
    .header-one {
      color: #fff;
      padding: 20px 0 0 11px;
    }
    .header-two {
      color: #fff;
      padding: 20px 25px 0 0;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
  .MyShop-center {
    height: 130px;
    margin: 100px 16px 0 16px;
    // border: 1px solid #000;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    .center-one {
      transform: translateY(-40px);
    }
    .MyShop-footer {
      font-size: 10px;
      color: #000;
      display: flex;
      p {
        width: 25%;
        display: flex;
        flex-direction: column;
        :first-child{
          // width: 25px;
          height: 24px;
        }
      }
    }
  }

  .custom-title {
    margin-right: 4px;
    vertical-align: middle;
  }

  .search-icon {
    font-size: 16px;
    line-height: inherit;
    color: #666;
  }
  .MyShop-footer-one {
    margin: 10px 16px 0 16px;
    :first-child {
      border-radius: 10px 10px 0 0;
    }
    :last-child {
      border-radius: 0 0 10px 10px;
    }
  }
  .MyShop-footer-two {
    margin: 10px 16px 0 16px;
    :first-child {
      border-radius: 10px 10px 10px 10px ;
    }
    
  }
}
</style>